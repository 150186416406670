import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Vi hjälper till' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Vi tar information från våra besökare seriöst, om du behöver hjälp med ett projekt, har frågor om sidan eller domänen. Tveka inte att kontakta oss.
      </Text>
    </Card>
  </Section>
)

export default Commitment
